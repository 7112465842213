import React from "react";
import { Input } from "antd";
import FloatLabel from "../float-label/Index";
import { ColorPicker } from "antd";

const ColorInput = ({
  validateImmediately,
  id,
  form,
  value,
  onChange,
  onKeyDown,
  onNext,
  readOnly,
  skipFocus,
  tabIndex,
  placeholder,
  required,
  forwardedRef,
  className,
  onFocus,
  defaultValue,
  bordered = true,
  ...restProps
}) => {
  // Override tabIndex if the field is read only
  if (readOnly || skipFocus) {
    tabIndex = -1;
  }

  const ref = forwardedRef || null;

  const handleKeyDown = async (event) => {
    if (onKeyDown) {
      const returnValue = onKeyDown(event);
      if (returnValue === false) {
        event.preventDefault();
        return false;
      }
    }

    if (event.key === "Enter" || (event.key === "Tab" && !event.shiftKey)) {
      if (validateImmediately && id && form) {
        try {
          await form.validateFields([id]);
        } catch (error) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }
      }
      if (onNext) {
        onNext(event);
      }
    }
  };

  return (
    <FloatLabel label={placeholder} required={required}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Input
          style={{
            borderRight: "none",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
          id={id}
          className={`custom-input ${className} ${bordered ? "ant-input-outlined" : "ant-input-borderless"} ${readOnly ? "read-only" : ""}`}
          value={value}
          onChange={readOnly ? null : onChange}
          ref={ref}
          readOnly={readOnly}
          tabIndex={tabIndex}
          onKeyDown={handleKeyDown}
          {...restProps}
        />
        <ColorPicker
          defaultValue="#22194DFF"
          value={value}
          size="small"
          format="hex"
          onChange={(value, hexString) => onChange({ target: { value: hexString } })}
        />
      </div>
    </FloatLabel>
  );
};

export default ColorInput;
