import React from "react";
import { Input } from "antd";
import FloatLabel from "../float-label/Index";

const CustomInput = ({
  validateImmediately,
  id,
  formElementId,
  form,
  uppercase = true,
  value,
  onChange,
  onKeyDown,
  onNext,
  readOnly,
  skipFocus,
  tabIndex,
  placeholder,
  required,
  forwardedRef,
  className,
  onFocus,
  bordered = true,
  textAlignRight,
  ...restProps
}) => {
  // Override tabIndex if the field is read only
  if (readOnly || skipFocus) {
    tabIndex = -1;
  }

  const ref = forwardedRef || null;

  const handleKeyDown = async (event) => {
    if (onKeyDown) {
      const returnValue = onKeyDown(event);
      if (returnValue === false) {
        event.preventDefault();
        return false;
      }
    }

    if (event.key === "Enter" || (event.key === "Tab" && !event.shiftKey)) {
      if (validateImmediately && form && (formElementId || id)) {
        try {
          await form.validateFields([formElementId || id]);
        } catch (error) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }
      }
      if (onNext) {
        onNext(event);
      }
    }
  };

  return (
    <FloatLabel label={placeholder} required={required}>
      <Input
        id={id}
        variant={bordered ? undefined : "borderless"}
        className={`custom-input ${className} ${textAlignRight ? "input-text-align-right" : ""} ${readOnly ? "read-only" : ""
          }`}
        value={value}
        onChange={readOnly ? null : onChange}
        ref={ref}
        onInput={(e) => {
          if (uppercase) {
            const start = e.target.selectionStart;
            const end = e.target.selectionEnd;
            e.target.value = e.target.value.toUpperCase();
            e.target.setSelectionRange(start, end);
          }
        }}
        onFocus={(e) => {
          e.target.select();
          onFocus?.();
        }}
        readOnly={readOnly}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        {...restProps}
      />
    </FloatLabel>
  );
};

export default CustomInput;
