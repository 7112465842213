import React from "react";
import { Router, Route } from "react-router-dom";
import SelectUser from "./pages/select-user/Index";
import Login from "./pages/login/Index";
import Signup from "./pages/signup/Index";
import ResetPin from "./pages/reset-pin/Index";
import { createMemoryHistory } from "history";
import { useEffect } from "react";
import { getSessions } from "./thunks/user-management";

const history = createMemoryHistory();

function UnauthenticatedRoutes() {
  useEffect(() => {
    const sessions = getSessions();
    if (sessions.length === 0) {
      history.push("/login")
    } else {
      history.push("/select-user")
    }
  }, [])

  return (
    <Router history={history}>
    <Route exact path="/select-user" component={SelectUser} />
    <Route exact path="/login" component={Login} />
    <Route exact path="/signup" component={Signup} />
    <Route exact path="/reset-pin" component={ResetPin} />
  </Router>
  );
}

export default UnauthenticatedRoutes;
