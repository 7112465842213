import React, { useRef, useState } from "react";
import { Form, Typography } from "antd";
import { CustomAntSelect, CustomModal, CustomSelect } from "../Index";
import { nanoid } from "nanoid";
import { useMouseTrap } from "../../../../utils";

const OptionPickerModal = ({ mode, options, value, handleCancel, handleSubmit, restProps }) => {
  const title = mode === "single" ? "Select single option" : "Select multiple options";
  const inputRef = useRef();
  const [form] = Form.useForm();
  const [modalElement, setModalElement] = useState();

  const [selectedOptions, setSelectedOptions] = useState(value ?? []);
  const selectedOptionsString = selectedOptions
    .map((optionId) => options.find((o) => o.value === optionId)?.label)
    .join(", ");

  const handleOnChange = (selectedOption) => {
    if (mode === "single") {
      handleSubmit([selectedOption]);
      handleCancel();
    } else {
      setSelectedOptions(selectedOption);
    }
  };

  const handleCancelModal = () => {
    if (mode === "multiple") {
      handleSubmit(form.getFieldValue("option"));
    }
    handleCancel();
  };

  useMouseTrap({ current: modalElement }, {
    "ctrl+s,alt+s,command+s": (e) => {
      e.preventDefault();
      if (mode === "single") {
        handleSubmit([form.getFieldValue("option")]);
      } else {
        handleSubmit(form.getFieldValue("option"));
      }
      handleCancel();
    },
  }, [modalElement]);

  return (
    <CustomModal title={title} onCancel={handleCancelModal} contentRef={setModalElement}>
      {mode === "multiple" && (
        <p>
          <b>Selected Options:</b> <span>{selectedOptionsString}</span>
        </p>
      )}
      <Form layout="vertical" form={form} initialValues={{ option: value }}>
        <Form.Item name="option">
          <CustomAntSelect
            forwardedRef={inputRef}
            autoFocus={true}
            options={options.map(obj => ({ ...obj, "data-optionid": obj.value }))}
            mode={mode === "multiple" ? "multiple" : undefined}
            onChange={handleOnChange}
            onNext={mode === "single" ? () => {
              const option = form.getFieldValue("option");
              if (typeof option === "string") {
                handleOnChange(option)
              } else {
                handleOnChange(option?.[0])
              }
            } : undefined}
            tagRender={() => null}
            {...restProps}
          />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

const AllSingleMultipleSelect = ({
  value,
  onChange,
  options,
  required,
  placeholder,
  autoFocus,
  populateOptionsForModeAll = false,
  id,
  allOptionName = "All",
  ...restProps
}) => {
  const [optionsModalVisible, setOptionsModalVisible] = useState(false);

  const modeOptions = [
    { value: "all", label: allOptionName },
    { value: "single", label: "Single" },
    { value: "multiple", label: "Multiple" },
  ]

  const handleOnChangeMode = (mode) => {
    if (mode === "all") {
      onChange({ mode: "all", options: populateOptionsForModeAll ? options.map(obj => obj.value) : [] });
    } else {
      onChange({ ...value, mode });
      setTimeout(() => setOptionsModalVisible(true));
    }
  };

  const handleSubmitOptions = (options) => {
    onChange({ ...value, options });
  };

  return (
    <>
      <CustomSelect
        id={`prevent_browser_autocomplete_${nanoid()}`}
        placeholder={placeholder}
        value={value?.mode}
        options={modeOptions}
        onChange={handleOnChangeMode}
        onSelect={handleOnChangeMode}
        autoComplete="new-password"
        autoFocus={autoFocus}
        required={required}
        selectedOptionLabelRender={() => {
          if (value?.options?.length) {
            const selectedOptionIds = value.options.reduce((acc, curr) => {
              acc[curr] = true;
              return acc;
            }, {});
            const label = options.filter(obj => selectedOptionIds[obj.value]).map(obj => obj.label).join(", ")
            return <Typography.Text style={{ width: "calc(100% - 32px)" }} ellipsis>{label}</Typography.Text>
          } else {
            return allOptionName
          }
        }}
      />
      {optionsModalVisible && (
        <OptionPickerModal
          options={options}
          mode={value?.mode}
          value={value?.options}
          handleSubmit={handleSubmitOptions}
          handleCancel={() => setOptionsModalVisible(false)}
          restProps={restProps}
        />
      )}
    </>
  );
};

export default AllSingleMultipleSelect;
