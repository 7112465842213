import React from "react";
import { Input } from "antd";
import FloatLabel from "../float-label/Index";

// This custom input component selects all the text in it when it's focussed
const CustomInputPassword = (
  { value, onChange, onKeyDown, onNext, readOnly, tabIndex, placeholder, required, ...restProps },
  ref
) => {
  // Override tabIndex if the field is read only
  if (readOnly) {
    tabIndex = -1;
  }

  const handleKeyDown = (event) => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    if (event.key === "Enter" || (event.key === "Tab" && !event.shiftKey)) {
      if (onNext) {
        onNext(event);
      }
    }
  };

  return (
    <FloatLabel label={placeholder} required={required}>
      <Input.Password
        className={`custom-input ${readOnly ? "read-only" : ""}`}
        value={value}
        onChange={readOnly ? null : onChange}
        ref={ref}
        onFocus={(e) => e.target.select()}
        readOnly={readOnly}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        {...restProps}
      />
    </FloatLabel>
  );
};

export default React.forwardRef(CustomInputPassword);
