import React, { useEffect, useRef } from "react";
import FloatLabel from "../float-label/Index";
import { formatDate } from "../../../../utils";
import { addDays, format, isValid, parse } from "date-fns";
import InputMask from "react-input-mask";

const DateInput = ({
  id,
  form,
  validateImmediately,
  value,
  onChange,
  readOnly,
  skipFocus,
  tabIndex,
  placeholder,
  required,
  forwardedRef,
  autoFocus,
  onNext,
  bordered = true,
  ...restProps
}) => {
  if (value === null || value === undefined) {
    value = "";
  }

  const dateValue = value;
  const ref = forwardedRef || useRef();

  value = formatDate(value);

  // Override tabIndex if the field is read only
  if (readOnly || skipFocus) {
    tabIndex = -1;
  }

  const handleOnChange = (e) => {
    let val = e.target.value;
    if (!val) {
      onChange(null);
      return;
    }

    const [date, month, year] = val.split("-");
    if (date && month && year) {
      val = `${year}-${month}-${date}`;
    }

    onChange(val);
  };

  const handleOnKeyDown = async (e) => {
    if (e.key === "ArrowUp" || e.key === "+") {
      if (value.length === 10) {
        const [date, month, year] = value.split("-");
        const dateVal = `${year}-${month}-${date}`;
        const dateObj = new Date(dateVal);
        if (isValid(dateObj)) {
          onChange(format(addDays(dateObj, 1), "yyyy-MM-dd"));
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
    if (e.key === "ArrowDown" || e.key === "-") {
      if (value.length === 10) {
        const [date, month, year] = value.split("-");
        const dateVal = `${year}-${month}-${date}`;
        const dateObj = new Date(dateVal);
        if (isValid(dateObj)) {
          onChange(format(addDays(new Date(dateVal), -1), "yyyy-MM-dd"));
          e.preventDefault();
          e.stopPropagation();
        }
      }
    }
    if (e.key === "Enter" || (e.key === "Tab" && !e.shiftKey)) {
      if (value?.length && !isValid(parse(dateValue, "yyyy-MM-dd", new Date()))) {
        e.preventDefault();
        e.stopPropagation();
        e.target.classList.add("error-input");
        return
      } else {
        e.target.classList.remove("error-input");
      }
      if (validateImmediately && id && form) {
        try {
          await form.validateFields([id]);
        } catch (error) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      }
      if (onNext) {
        onNext(e)
      }
    }
  };
  const moveCursorToStart = (target) => {
    if (target) {
      target.selectionStart = 0;
      target.selectionEnd = 0;
    }
  };

  useEffect(() => {
    if (autoFocus) {
      moveCursorToStart(ref?.current?.state?.input);
    }
  }, []);

  return (
    <FloatLabel label={placeholder} required={required}>
      <InputMask
        id={id}
        className={`custom-input ant-input ${bordered ? "ant-input-outlined" : "ant-input-borderless"} ${readOnly ? "read-only" : ""}`}
        tabIndex={tabIndex}
        mask="99-99-9999"
        maskPlaceholder={null}
        placeholder=""
        value={value}
        onChange={handleOnChange}
        ref={ref}
        autoFocus={autoFocus}
        onFocusCapture={(e) => moveCursorToStart(e.target)}
        onKeyDownCapture={handleOnKeyDown}
        {...restProps}
      />
    </FloatLabel>
  );
};

export default DateInput;
