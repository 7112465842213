import React from "react";
import { useRef } from "react";
import { useState } from "react";
import Draggable from "react-draggable";

const DraggableContainer = ({
  children,
  defaultPosition,
  enforceProgrammaticBounds,
  contentProps = {},
  suppressDragging,
  allowDraggingByKeyboard,
}) => {
  const ref = useRef();

  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState();
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const enableDragging = (enable) => {
    setDisabled(!enable);
  };

  const handleOnStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = ref.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  if (enforceProgrammaticBounds) {
    const minX = 0 - (defaultPosition?.x || 0);
    const minY = -(
      document.body.clientHeight -
      (defaultPosition?.y || 0) -
      (ref.current?.clientHeight || 0)
    );

    const maxX =
      document.body.clientWidth - (defaultPosition?.x || 0) - (ref.current?.clientWidth || 0);
    const maxY = 0 + (defaultPosition?.y || 0);

    if (position.x < minX) {
      position.x = minX;
    }
    if (position.x > maxX) {
      position.x = maxX;
    }

    if (position.y < minY) {
      position.y = minY;
    }
    if (position.y > maxY) {
      position.y = maxY;
    }
  }

  return (
    <Draggable
      disabled={suppressDragging ? true : disabled}
      position={position}
      bounds={bounds}
      onStart={handleOnStart}
      defaultPosition={defaultPosition}
      onDrag={(e, dragData) => setPosition({ x: dragData.x, y: dragData.y })}
    >
      <div
        id="draggable"
        {...contentProps}
        ref={(e) => {
          contentProps.ref(e);
          ref.current = e;
        }}
        onKeyDown={(e) => {
          if (allowDraggingByKeyboard) {
            switch (e.key) {
              case "ArrowRight": {
                if (e.shiftKey) {
                  e.preventDefault();
                  setPosition({
                    ...position,
                    x: position.x + 50,
                  });
                }
                break;
              }
              case "ArrowLeft": {
                if (e.shiftKey) {
                  e.preventDefault();
                  setPosition({
                    ...position,
                    x: position.x - 50,
                  });
                }
                break;
              }
              case "ArrowUp": {
                if (e.shiftKey) {
                  e.preventDefault();
                  setPosition({
                    ...position,
                    y: position.y - 50,
                  });
                }
                break;
              }
              case "ArrowDown": {
                if (e.shiftKey) {
                  e.preventDefault();
                  setPosition({
                    ...position,
                    y: position.y + 50,
                  });
                }
                break;
              }
            }
          }
          if (contentProps.onKeyDown) {
            contentProps.onKeyDown(e);
          }
        }}
        onMouseOver={
          suppressDragging
            ? undefined
            : (e) => {
              if (disabled && e.target.nodeName !== "INPUT") {
                enableDragging(true);
              }
            }
        }
        onMouseOut={suppressDragging ? undefined : () => enableDragging(false)}>{children}</div>
    </Draggable>
  );
};

export default DraggableContainer;