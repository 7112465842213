import { get, set } from "automate-redux";
import { axios } from "../client";
import { getDaybooks } from "./master";
import { getSelectedOrgId, loadOrganizationDetails } from "./user-management";
import store from "../store";
import { uploadFile } from "../utils";

export const loadSettings = () => async (dispatch, getState) => {
  const state = getState();
  const params = { org_id: getSelectedOrgId(state) };
  const { status, data } = await axios.get("/v1/settings", { params });
  if (status !== 200) {
    throw data;
  }

  dispatch(set("settings", data));
};

export const loadPrintSettings = () => async (dispatch, getState) => {
  const state = getState();
  const params = { org_id: getSelectedOrgId(state) };
  const { status, data } = await axios.get("/v1/getPrintSettings", { params });
  if (status !== 200) {
    throw data;
  }

  dispatch(set("printSettings", data));
};

export const updateSettingRecordDetails = (type, id, details) => async (dispatch, getState) => {
  const state = getState();
  const settings = getSettings(state);
  const body = { details, org_id: getSelectedOrgId(state) };
  const { status, data } = await axios.post(`/v1/settings/${type}/${id}`, body);
  if (status !== 200) {
    throw data;
  }

  const newSettings = settings.map((obj) => {
    if (obj.type === type && obj.id === id) {
      return { ...obj, details };
    }
    return obj;
  });
  if (!newSettings.find(obj => obj.type === type && obj.id === id)) {
    newSettings.push({ id, type, details })
  }

  dispatch(set("settings", newSettings));
};

export const deleteSettings = (type, ids) => async (dispatch, getState) => {
  const state = getState();
  const settings = getSettings(state);
  const body = { org_id: getSelectedOrgId(state), type, ids };
  const { status, data } = await axios.post(`/v1/deleteSettings`, body);
  if (status !== 200) {
    throw data;
  }

  const newSettings = settings.filter((obj) => obj.type !== type || !ids.includes(obj.id));
  dispatch(set("settings", newSettings));
};

export const updatePrintSettings = (docType, format, source, template, options) => async (dispatch, getState) => {
  const state = getState();
  const settings = getPrintSettings(state);
  const body = { org_id: getSelectedOrgId(state), doc_type: docType, format, source, template, options };
  const { status, data } = await axios.post(`/v1/updatePrintSettings`, body);
  if (status !== 200) {
    throw data;
  }

  const newSettings = settings.map((obj) => {
    if (obj.doc_type === docType && obj.format === format) {
      return { ...obj, source, options };
    }
    return obj;
  });
  dispatch(set("printSettings", newSettings));
};

export const getSettings = (state) => get(state, "settings", []);
export const getReportsOfTypes = (state, reportTypes) => {
  const allReports = getSettings(state).filter(obj => obj.type === "REPORT_FORMAT")
  const reports = allReports.filter(obj => reportTypes.includes(obj.details?.type));
  return reports.sort((a, b) => {
    if (a.details.order == null) {
      return 1
    } else if (b.details.order == null) {
      return -1
    } else if (a.details.order != b.details.order) {
      return a.details.order < b.details.order ? -1 : 1;
    } else {
      return a.details.name < b.details.name ? -1 : 1
    }
  })
}
export const getSettingRecordDetails = (state, type, id) => {
  const settings = getSettings(state);
  const settingRecord = settings.find((obj) => obj.type === type && obj.id === id);
  return settingRecord?.details ?? {};
};

export const getPrintSettings = (state) => get(state, "printSettings", []);

export const getDaybookSetting = (state, id) => {
  const daybookSettings = getDaybooks(state);
  const index = daybookSettings.findIndex((obj) => obj.id === id);
  return index >= 0 ? daybookSettings[index] : {};
};

export const fetchPrintTemplate = async (id) => {
  const queryParams = {
    id,
  };
  const response = await axios.get("/v1/downloadPrintTemplate", {
    params: queryParams,
  });
  if (response.status !== 200) {
    return Promise.reject(response.data);
  }
  return Promise.resolve(response.data);
};

export const fetchOrgPrintTemplate = async (id) => {
  const state = store.getState();
  const queryParams = {
    org_id: getSelectedOrgId(state),
    id,
  };
  const response = await axios.get("/v1/downloadOrgPrintTemplate", {
    params: queryParams,
  });
  if (response.status !== 200) {
    return Promise.reject(response.data);
  }
  return Promise.resolve(response.data);
};

export const uploadOrgPrintTemplate = async (id, template) => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    id,
    template,
  };
  const response = await axios.post("/v1/uploadOrgPrintTemplate", reqBody);
  if (response.status !== 200) {
    return Promise.reject(response.data);
  }
  return Promise.resolve();
};

export const addOrgPrintTemplate = (docType, format, template) => async (dispatch, getState) => {
  const state = getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    doc_type: docType,
    format,
    template,
  };
  const response = await axios.post("/v1/addOrgPrintTemplate", reqBody);
  if (response.status !== 200) {
    return Promise.reject(response.data);
  }
  await dispatch(loadPrintSettings());
  return Promise.resolve();
};

export const updatePrintSettingSource = (docType, format, source) => async (dispatch, getState) => {
  const state = getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    doc_type: docType,
    format,
    source
  };
  const response = await axios.post("/v1/updatePrintSettingSource", reqBody);
  if (response.status !== 200) {
    return Promise.reject(response.data);
  }
  await dispatch(loadPrintSettings());
  return Promise.resolve();
};

export const updatePrintSettingFormatName = (docType, format, newFormat) => async (dispatch, getState) => {
  const state = getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    doc_type: docType,
    format,
    newFormat
  };
  const response = await axios.post("/v1/updatePrintSettingFormatName", reqBody);
  if (response.status !== 200) {
    return Promise.reject(response.data);
  }
  await dispatch(loadPrintSettings());
  return Promise.resolve();
};

export const deleteOrgPrintTemplate = (docType, format) => async (dispatch, getState) => {
  const state = getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
    doc_type: docType,
    format,
  };
  const response = await axios.post("/v1/deleteOrgPrintTemplate", reqBody);
  if (response.status !== 200) {
    return Promise.reject(response.data);
  }
  await dispatch(loadPrintSettings());
  return Promise.resolve();
};

export const uploadSignature = async (file) => {
  const state = store.getState();
  await uploadFile("/v1/uploadSignature", file, { org_id: getSelectedOrgId(state) });
  await store.dispatch(loadOrganizationDetails());
}

export const uploadLogo = async (file) => {
  const state = store.getState();
  await uploadFile("/v1/uploadLogo", file, { org_id: getSelectedOrgId(state) });
  await store.dispatch(loadOrganizationDetails());
}

export const uploadWatermark = async (file) => {
  const state = store.getState();
  await uploadFile("/v1/uploadWatermark", file, { org_id: getSelectedOrgId(state) });
  await store.dispatch(loadOrganizationDetails());
}

export const requestOrgGmailOAuth = async () => {
  const state = store.getState();
  const reqBody = {
    org_id: getSelectedOrgId(state),
  };
  const { status, data } = await axios.post("/v1/requestOrgGmailOAuthUrl", reqBody);
  if (status !== 200) {
    return Promise.reject(data);
  }
  return data
};