import React, { useState } from "react";
import "./Index.css";

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false);
  const { children, label, required } = props;

  const labelClass = focus ? "label label-focus" : "label";

  if (!label) {
    return children;
  }

  return (
    <div onBlur={() => setFocus(false)} onFocus={() => setFocus(true)} style={{ width: "100%" }}>
      <label className={labelClass} style={{ color: "var(--text-color-primary)" }}>
        {label}
        <span style={{ color: "#ff4d4f" }}>{required ? "*" : ""}</span>
      </label>
      {children}
    </div>
  );
};

export default FloatLabel;
