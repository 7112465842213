import React, { useEffect, useState } from "react";
import { Input } from "antd";
import FloatLabel from "../float-label/Index";
import localEventsManager from "../../../../localEventsManager";
import { formatNumber } from "../../../../utils";

const numberToString = (num) => (num !== undefined && num !== null ? String(num) : "");

const convertStringToNumber = (strValue) => {
  if (strValue === "-") {
    return { valid: true, value: -0 };
  }

  if (strValue === undefined || strValue === null || strValue === "") {
    return { valid: true, value: null };
  }

  const numVal = Number(strValue.replace(/,/g, ""));
  if (!Number.isNaN(numVal)) {
    return { valid: true, value: numVal };
  }

  return { valid: false };
};

const CustomInputNumber = ({
  id,
  formElementId,
  form,
  validateImmediately,
  className,
  value,
  onChange,
  onKeyDown,
  onNext,
  textAlignRight,
  onFocus,
  readOnly,
  tabIndex,
  skipFocus,
  placeholder,
  required,
  forwardedRef,
  decimalPlaces,
  formattingDecimalPlaces = decimalPlaces,
  ...restProps
}) => {
  // Override tabIndex if the field is read only
  if (readOnly || skipFocus) {
    tabIndex = -1;
  }

  const ref = forwardedRef || null;
  const [data, setData] = useState(numberToString(value));
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    const num = convertStringToNumber(data);
    if (num.value !== value) {
      setData(numberToString(value));
    }
  }, [value]);

  const handleChange = (e) => {
    let stringValue = e.target.value;
    if (stringValue && stringValue.includes(".")) {
      const strArr = stringValue.split(".");
      if (strArr.length === 2 && strArr[1]) {
        stringValue = `${strArr[0]}.${strArr[1].slice(0, decimalPlaces ?? 2)}`;
      } else if (stringValue === ".") {
        stringValue = `${0}.`
      } else if (stringValue === "-.") {
        stringValue = `-${0}.`
      }
    }
    const num = convertStringToNumber(stringValue);
    if (num.valid) {
      setData(stringValue);
      onChange(num.value);
    }
  };

  const handleFocus = (event) => {
    if (onFocus) {
      onFocus(event);
    }
    setFocus(true);
    const target = event.currentTarget;
    // Added a slight delay since target.select was not working properly without it
    setTimeout(() => {
      if (document.activeElement === target) {
        target.select();
      }
    }, 0);
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Delete") {
      setData(String(""));
      onChange(null);
    }

    if (onKeyDown) {
      onKeyDown(event);
    }

    if (event.key === "Enter" || (event.key === "Tab" && !event.shiftKey)) {
      if (validateImmediately && form && (formElementId || id)) {
        try {
          await form.validateFields([formElementId || id]);
        } catch (error) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }
      }
      if (onNext) {
        onNext(event);
      }
    }

    if (event.key === "F12") {
      localEventsManager.subsrcibeOnce("calculatorOutput", (value) => {
        setData(String(value));
        onChange(value);
      });
    }
  };

  const formattedNumber = focus ? data : formatNumber(data, formattingDecimalPlaces);

  return (
    <FloatLabel label={placeholder} required={required}>
      <Input
        id={id}
        className={`custom-input ${className} ${textAlignRight ? "input-text-align-right" : ""} ${readOnly ? "read-only" : ""
          }`}
        value={formattedNumber}
        onChange={readOnly ? null : handleChange}
        ref={ref}
        onFocus={handleFocus}
        onBlurCapture={() => setFocus(false)}
        onBlur={() => setFocus(false)}
        readOnly={readOnly}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        type="search"
        autoComplete="off"
        {...restProps}
      />
    </FloatLabel>
  );
};

export default CustomInputNumber;
