import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Typography } from "antd";
import { useDispatch } from "react-redux";
import { CustomInput, CustomInputPassword } from "../../components/utils/custom-elements/Index";
import { getLoginOtp, verifyLoginOtp } from "../../thunks/user-management";
import { decrementPendingRequests, incrementPendingRequests, notifySuccess } from "../../utils";
import { useRef } from "react";
import { nanoid } from "nanoid";
import LoginSignupPage from "./LoginSignupPage";

export default () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [reqId, setReqId] = useState();
  const otpInputRef = useRef({});

  const handleSubmit = async () => {
    try {
      incrementPendingRequests();
      const { email } = await form.validateFields()
      const reqId = await getLoginOtp(email);
      setReqId(reqId);
      otpInputRef.current?.focus();
      notifySuccess("Sent OTP to your email successfully");
    } finally {
      decrementPendingRequests();
    }
  };

  const handleValidateOtp = async (otp) => {
    try {
      incrementPendingRequests();
      await dispatch(verifyLoginOtp(reqId, otp));
      notifySuccess("OTP verified successfully");
    } finally {
      decrementPendingRequests();
    }
  };

  return (
    <LoginSignupPage>
      <div style={{ marginTop: 32, fontSize: 24, fontWeight: 300 }}>
        <span>Log</span> <span style={{ color: "#00a1e4" }}>in</span>
      </div>
      <Form layout="vertical" form={form} style={{ marginTop: 24 }}>
        <Form.Item
          name="email"
          validateTrigger="onBlur"
          rules={[
            { required: true },
            {
              type: "email",
              message: "Provide a valid email",
            },
          ]}
        >
          <CustomInput required placeholder="Email" autoFocus onKeyDown={event => {
            if (event.key === "Enter") {
              handleSubmit()
            }
          }} />
        </Form.Item>
        {!reqId && (
          <Button
            size="large"
            type="primary"
            block
            style={{ marginTop: 16 }}
            onClick={handleSubmit}
          >
            Get OTP for Login
          </Button>
        )}
        {reqId && (
          <>
            <Form.Item name="otp" rules={[{ required: true }]}>
              <CustomInputPassword
                placeholder="OTP"
                id={`prevent_browser_autocomplete_${nanoid()}`}
                ref={otpInputRef}
                onChange={(event) => {
                  const pin = event.target.value;
                  if (pin.length === 6) {
                    handleValidateOtp(pin);
                  }
                }}
              />
            </Form.Item>
          </>
        )}
        <Typography.Paragraph
          type="secondary"
          style={{ margin: "16px 0px 0px", textAlign: "center" }}
        >
          Not registered yet? <Link to="/signup">Signup here</Link>
        </Typography.Paragraph>
      </Form>
    </LoginSignupPage>
  );
};
