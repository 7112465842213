import React, { useRef } from "react";
import { Button, Avatar } from "antd";
import { useDispatch } from "react-redux";
import { CustomInputPassword } from "../../components/utils/custom-elements/Index";
import { getSessions, verifyPin } from "../../thunks/user-management";
import { decrementPendingRequests, incrementPendingRequests, useMouseTrap } from "../../utils";
import { useState } from "react";
import { useEffect } from "react";
import { nanoid } from "nanoid";
import LoginSignupPage from "../login/LoginSignupPage";

export default ({ history }) => {
  const dispatch = useDispatch();
  const sessions = getSessions();

  const prevSessionId = localStorage.getItem("selectedSessionId");
  const prevSessionIndex =
    sessions.length === 1 ? 0 : sessions.findIndex((obj) => obj.id === prevSessionId);

  const [focusedIndex, setFocusedIndex] = useState(prevSessionIndex > -1 ? prevSessionIndex : 0);
  const [selectedIndex, setSelectedIndex] = useState(prevSessionIndex > -1 ? prevSessionIndex : -1);
  const selectedSession = sessions[selectedIndex];
  const pinInputRef = useRef();

  const handleSubmitPin = async (pin) => {
    const selectedSessionId = selectedSession.id;
    try {
      incrementPendingRequests();
      await dispatch(verifyPin(selectedSessionId, pin));
    } catch (error) {
      if (error?.response?.data?.msg === "Session expired") {
        const sessions = getSessions();
        if (sessions.length === 0) {
          history.push("/login")
        }
      }
    } finally {
      decrementPendingRequests();
    }
  };

  useEffect(() => {
    if (selectedIndex > -1) {
      pinInputRef.current?.focus();
    }
  }, [selectedIndex]);

  useMouseTrap(
    { current: document.body },
    {
      escape: () => {
        setSelectedIndex(-1);
      },
      down: () => {
        if (focusedIndex + 1 < sessions.length) {
          setFocusedIndex(focusedIndex + 1);
        }
      },
      up: () => {
        if (focusedIndex > 0) {
          setFocusedIndex(focusedIndex - 1);
        }
      },
      enter: () => {
        setSelectedIndex(focusedIndex);
      },
    },
    [focusedIndex]
  );

  return (
    <LoginSignupPage>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ flex: 1 }}></div>
        <div style={{ marginTop: 32, fontSize: 24, fontWeight: 300 }}>
          <span>Screen</span> <span style={{ color: "#00a1e4" }}>Lock</span>
        </div>
        {!selectedSession && (
          <>
            <div style={{ marginTop: 24, border: "1px solid #ccc" }}>
              {sessions.map((obj, index) => (
                <div
                  key={obj.id}
                  style={{
                    padding: 8,
                    cursor: "pointer",
                    backgroundColor: index === focusedIndex ? "rgba(1, 171, 239, 0.1)" : "white",
                    borderTop: index === 0 ? undefined : "1px solid #ccc",
                  }}
                  onClick={() => {
                    setFocusedIndex(index);
                    setSelectedIndex(index);
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      shape="circle"
                      style={{
                        background: "rgba(1, 171, 239, 0.3)",
                        color: "rgba(0,0,0,0.87)",
                        width: 32,
                        height: 32,
                        lineHeight: "32px",
                      }}
                    >
                      {obj.fname.charAt(0)}
                    </Avatar>
                    <div style={{ marginLeft: 16, flex: 1 }}>
                      <div style={{ color: "rgba(0,0,0,0.87)" }}>
                        {obj.fname} {obj.lname}
                      </div>
                      <div style={{ color: "rgba(0,0,0,0.54)", fontSize: 12 }}>{obj.email}</div>
                    </div>
                    <span
                      className="material-icons-outlined"
                      style={{ marginLeft: 8, fontSize: 20 }}
                    >
                      navigate_next
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <Button
              block
              size="large"
              style={{ marginTop: 24 }}
              onClick={() => history.push("/login")}
            >
              Add Account
            </Button>
          </>
        )}
        {selectedSession && (
          <>
            <div style={{ border: "1px solid #ccc", borderRadius: 2, padding: 8, marginTop: 24, display: "flex", alignItems: "center", color: "grey" }}>
              <span
                className="material-icons-outlined"
                style={{ fontSize: 18, marginRight: 8 }}
              >
                person
              </span>
              {selectedSession.fname} {selectedSession.lname}
            </div>
            <CustomInputPassword
              style={{ marginTop: 8 }}
              id={`prevent_browser_autocomplete_${nanoid()}`}
              ref={pinInputRef}
              onChange={(event) => {
                const pin = event.target.value;
                if (pin.length === 4) {
                  handleSubmitPin(pin);
                }
              }}
              prefix={
                <span
                  className="material-icons-outlined"
                  style={{ fontSize: 18, marginRight: 8, color: "grey" }}
                >
                  lock
                </span>
              }
            />
            <div
              style={{ marginTop: 16, display: "flex", justifyContent: "space-between" }}>
              <a onClick={() => history.push("/reset-pin", { sessionId: selectedSession.id })}>
                Forgot pin?
              </a>
              {selectedSession && (
                <a style={{ display: "flex" }}
                  onClick={() => setSelectedIndex(-1)}
                >
                  <span className="material-icons-outlined">
                    navigate_before
                  </span>
                  Back to all accounts
                </a>
              )}
            </div>
          </>
        )}
        <div style={{ flex: 1 }}></div>
      </div>
    </LoginSignupPage>
  );
};
