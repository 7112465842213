import React, { useRef } from "react";
import DraggableContainer from "../../draggable-container/Index";
import ReactModal from 'react-modal';
import Mousetrap from "mousetrap";

export default ({
  enableDragging,
  children,
  onCancel,
  width = "520px",
  height = "fit-content",
  contentStyle,
  zIndex = 1000,
  enforceProgrammaticBounds,
  defaultPosition,
  allowDraggingByKeyboard,
  title,
  styles,
  contentRef,
  mask = true,
  mouseTrapKeyHandlers,
  expandible
}) => {
  const triggerElement = useRef(document.activeElement);
  let mouseTrapRef = useRef();
  const customContentStyle = {
    position: "absolute",
    top: "10%",
    left: 0,
    right: 0,
    bottom: "20%",
    marginLeft: "auto",
    marginRight: "auto",
    width: expandible ? "fit-content" : width,
    height: height,
    display: "inline-block",
    padding: title ? 0 : undefined,
    border: "none",
    ...styles?.content,
    ...contentStyle
  };
  const customOverlayStyle = mask ? {
    background: "rgba(0, 0, 0, 0.45)",
    zIndex,
    overflowY: "auto"
  } : {
    width: customContentStyle.width,
    height: customContentStyle.height,
    position: "relative"
  };
  return (
    <>
      <ReactModal
        isOpen={true}
        // Note: setTimeOut is necessary here otherwise it causes bug where in user is not able to type rate after rate popup is closed
        onAfterClose={() => setTimeout(() => triggerElement.current?.focus?.())}
        parentSelector={() => document.querySelector("#root")}
        onRequestClose={(e) => {
          onCancel?.(e);
          if (mouseTrapKeyHandlers) {
            Object.keys(mouseTrapKeyHandlers).forEach((key) => {
              mouseTrapRef?.current.unbind(key.split(","));
            });
          }
        }}
        shouldReturnFocusAfterClose={false}
        contentRef={(ref) => {
          contentRef?.(ref);
          if (ref && mouseTrapKeyHandlers && Object.keys(mouseTrapKeyHandlers).length > 0) {
            mouseTrapRef.current = new Mousetrap(ref);
            Object.entries(mouseTrapKeyHandlers).forEach(([key, handler]) => {
              mouseTrapRef?.current.bind(key.split(","), handler);
            });
          }
        }}
        ariaHideApp={false}
        style={{
          overlay: customOverlayStyle,
          content: customContentStyle,
        }}
        contentElement={enableDragging ? (props, children) => {
          return (
            <DraggableContainer
              contentProps={props}
              enforceProgrammaticBounds={enforceProgrammaticBounds}
              defaultPosition={defaultPosition}
              allowDraggingByKeyboard={allowDraggingByKeyboard}
            >
              {children}
            </DraggableContainer>
          )
        } : undefined}
      >
        {title ? (<div
          style={{ width: "100%", height: "fit-content", display: "flex", flexDirection: "column" }}
        >
          <div style={{ fontSize: 16, fontWeight: "bold", padding: 16, textAlign: "center", borderBottom: "1px solid #ccc" }}>
            {title}
          </div>
          <div style={{ padding: 24, width: "100%", boxSizing: "border-box", flex: 1 }}>
            {children}
          </div>
        </div>) : (
          <>{children}</>
        )}
      </ReactModal>
    </>
  );
};
