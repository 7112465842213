import React from "react";
import { AutoComplete, Input } from "antd";
import FloatLabel from "../float-label/Index";

const CustomAutoComplete = ({
  id,
  form,
  uppercase = true,
  validateImmediately,
  value,
  onChange,
  onKeyDown,
  onNext,
  readOnly,
  skipFocus,
  tabIndex,
  placeholder,
  required,
  forwardedRef,
  className,
  onFocus,
  options,
  onSelect,
  ...restProps
}) => {
  // Override tabIndex if the field is read only
  if (readOnly || skipFocus) {
    tabIndex = -1;
  }

  const ref = forwardedRef || null;

  const handleKeyDown = async (event) => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    if (event.key === "Enter" || (event.key === "Tab" && !event.shiftKey)) {
      if (validateImmediately && id && form) {
        try {
          await form.validateFields([id]);
        } catch (error) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }
      }
      if (onNext) {
        onNext(event);
      }
    }
  };

  const filteredOptions = options.filter((obj) => obj.label.includes(value));

  return (
    <FloatLabel label={placeholder} required={required}>
      <AutoComplete
        className={`custom-input ${readOnly ? "read-only" : ""} ${className}`}
        value={value}
        onChange={readOnly ? null : onChange}
        ref={ref}
        onFocus={(e) => {
          e.target.select();
          onFocus?.();
        }}
        readOnly={readOnly}
        tabIndex={tabIndex}
        onKeyDown={handleKeyDown}
        options={filteredOptions}
        onSelect={(_, option) => onSelect?.(option)}
        type="search"
        autoComplete="off"
        {...restProps}
      >
        <Input
          onInput={(e) => {
            if (uppercase) {
              const start = e.target.selectionStart;
              const end = e.target.selectionEnd;
              e.target.value = e.target.value.toUpperCase();
              e.target.setSelectionRange(start, end);
            }
          }}
        />
      </AutoComplete>
    </FloatLabel>
  );
};

export default CustomAutoComplete;
