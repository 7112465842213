import React, { useRef } from "react";
import { Button, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CustomInputPassword, CustomSelect } from "../../components/utils/custom-elements/Index";
import {
  getOrgAccessOtp,
  getOrganizations,
  getSelectedOrgId,
  getSessions,
  verifyOrgAccessOtp,
} from "../../thunks/user-management";
import { decrementPendingRequests, incrementPendingRequests, notifySuccess, useMouseTrap } from "../../utils";
import { useState } from "react";
import { CompanySelectorModal } from "../../components/topbar/Index";
import { nanoid } from "nanoid";
import LoginSignupPage from "../login/LoginSignupPage";

export default () => {
  const dispatch = useDispatch();
  const sessions = getSessions();
  const sessionId = localStorage.getItem("selectedSessionId");
  const session = sessions.find((obj) => obj.id === sessionId) || {};
  const [reqId, setReqId] = useState();
  const [form] = Form.useForm();
  const [companySelectorPopupVisible, setCompanySelectorPopupVisible] = useState(false);
  const selectedOrgId = useSelector((state) => getSelectedOrgId(state));
  const orgs = useSelector((state) => getOrganizations(state));
  const selectedOrg = orgs.find((obj) => obj.id === selectedOrgId);
  const selectedOrgName = selectedOrg?.name;
  const otpInputRef = useRef({});

  const handleClickRequestAccess = async () => {
    try {
      incrementPendingRequests();
      const { period } = await form.validateFields();
      const reqId = await getOrgAccessOtp(selectedOrgId, period);
      setReqId(reqId);
      notifySuccess("OTP sent to admin");
      otpInputRef.current?.focus();
    } finally {
      decrementPendingRequests();
    }
  };

  const handleVerifyAdminOtp = async () => {
    try {
      incrementPendingRequests();
      const { otp } = await form.validateFields();
      await dispatch(verifyOrgAccessOtp(reqId, otp));
      notifySuccess("Access granted");
    } finally {
      decrementPendingRequests();
    }
  };

  useMouseTrap(
    { current: document.body },
    {
      "alt+f1": (e) => {
        e.preventDefault();
        setCompanySelectorPopupVisible(true);
      },
    },
    []
  );

  return (
    <LoginSignupPage>
      <div style={{ marginTop: 32, fontSize: 24, fontWeight: 300 }}>
        <span>Request</span> <span style={{ color: "#00a1e4" }}>Access</span>
      </div>
      <div style={{ color: "rgba(0,0,0,0.54)", marginBottom: 24 }}>
        Admin approval is required for access in {selectedOrgName}
      </div>
      <div style={{ border: "1px solid #ccc", borderRadius: 2, padding: 8, marginTop: 24, display: "flex", alignItems: "center", color: "grey" }}>
        <span
          className="material-icons-outlined"
          style={{ fontSize: 18, marginRight: 8 }}
        >
          person
        </span>
        {session.fname} {session.lname}
      </div>
      <Form form={form} style={{ marginTop: 16 }}>
        {!reqId ? (
          <>
            <Form.Item name="period" rules={[{ required: true }]}>
              <CustomSelect
                placeholder="Period"
                required
                options={[
                  { label: "5 mins", value: "5 mins" },
                  { label: "15 mins", value: "15 mins" },
                  { label: "30 mins", value: "30 mins" },
                  { label: "1 hour", value: "1 hour" },
                  { label: "2 hours", value: "2 hours" },
                  { label: "4 hours", value: "4 hours" },
                  { label: "8 hours", value: "8 hours" },
                  { label: "7 days", value: "7 days" },
                  { label: "30 days", value: "30 days" },
                  { label: "1 year", value: "1 year" },
                  { label: "3 years", value: "3 years" },
                  { label: "5 years", value: "5 years" },
                ]}
              />
            </Form.Item>
            <Button
              size="large"
              block
              type="primary"
              style={{ marginTop: 16 }}
              onClick={handleClickRequestAccess}
            >
              Send OTP to admin
            </Button>
          </>
        ) : (
          <Form.Item name="otp" rules={[{ required: true }]}>
            <CustomInputPassword
              placeholder="OTP"
              id={`prevent_browser_autocomplete_${nanoid()}`}
              ref={otpInputRef}
              onChange={(event) => {
                const pin = event.target.value;
                if (pin.length === 6) {
                  handleVerifyAdminOtp(pin);
                }
              }}
            />
          </Form.Item>
        )}
      </Form>
      <Button
        block
        size="large"
        type="link"
        style={{
          marginTop: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white"
        }}
        onClick={() => setCompanySelectorPopupVisible(true)}
      >
        Change company (Alt+F1)
      </Button>
      {companySelectorPopupVisible && (
        <CompanySelectorModal handleCancel={() => setCompanySelectorPopupVisible(false)} zIndex={3000} />
      )}
    </LoginSignupPage>
  );
};
