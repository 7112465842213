import React from "react";
import { Button, Form } from "antd";
import { CustomInputPassword } from "../../components/utils/custom-elements/Index";
import { getResetPinOtp, getSessions, resetPin } from "../../thunks/user-management";
import { decrementPendingRequests, incrementPendingRequests, notifySuccess, useMouseTrap } from "../../utils";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { nanoid } from "nanoid";
import LoginSignupPage from "../login/LoginSignupPage";

export default ({ history }) => {
  const location = useLocation();
  const sessions = getSessions();
  const sessionId = location.state?.sessionId;
  const session = sessions.find((obj) => obj.id === sessionId) || {};
  const [reqId, setReqId] = useState();
  const [form] = Form.useForm();

  const handleClickRequestOtp = async () => {
    try {
      incrementPendingRequests();
      const reqId = await getResetPinOtp(sessionId);
      setReqId(reqId);
      notifySuccess("Sent OTP to your email successfully");
    } finally {
      decrementPendingRequests();
    }
  };

  const handleClickResetPin = async () => {
    try {
      incrementPendingRequests();
      const { otp, pin } = await form.validateFields();
      await resetPin(sessionId, reqId, otp, pin);
      notifySuccess("PIN resetted successfully");
      history.goBack();
    } finally {
      decrementPendingRequests();
    }
  };

  useMouseTrap(
    { current: document.body },
    {
      escape: () => {
        history.goBack();
      },
    },
    []
  );

  return (
    <LoginSignupPage>
      <div style={{ marginTop: 32, fontSize: 24, fontWeight: 300 }}>
        <span>Reset</span> <span style={{ color: "#00a1e4" }}>Pin</span>
      </div>
      <div style={{ border: "1px solid #ccc", borderRadius: 2, padding: 8, marginTop: 24, display: "flex", alignItems: "center", color: "grey" }}>
        <span
          className="material-icons-outlined"
          style={{ fontSize: 18, marginRight: 8 }}
        >
          person
        </span>
        {session.fname} {session.lname}
      </div>
      {!reqId ? (
        <Button block size="large" type="primary" onClick={handleClickRequestOtp} style={{ marginTop: 16 }}>
          Request OTP for PIN reset
        </Button>
      ) : (
        <>
          <Form form={form} style={{ marginTop: 16 }}>
            <Form.Item name="pin" rules={[{ required: true }]}>
              <CustomInputPassword placeholder="New PIN" autoFocus autoComplete="new-password" />
            </Form.Item>
            <Form.Item name="otp" rules={[{ required: true }]}>
              <CustomInputPassword placeholder="OTP" id={`prevent_browser_autocomplete_${nanoid()}`} />
            </Form.Item>
            <Button
              block
              size="large"
              type="primary"
              onClick={handleClickResetPin}
              style={{ marginTop: 16 }}
            >
              Reset PIN
            </Button>
          </Form>
        </>
      )}
    </LoginSignupPage>
  );
};
