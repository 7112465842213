import { createStore, applyMiddleware, compose } from "redux";
import { generateReducers } from "automate-redux";
import thunk from "redux-thunk";

// Initial state of redux
const initialState = {
  pendingRequests: 0,
};

const enhancers = [applyMiddleware(thunk)];

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const composedEnhancers = compose(...enhancers);

// Generate reducers with the initial state and pass it to the redux store
export default createStore(generateReducers(initialState), composedEnhancers);
