import React, { useState, useRef, useEffect } from "react";
import { Select, Divider, Space, Button } from "antd";
import FloatLabel from "../float-label/Index";
import { nanoid } from "nanoid";

const getFocusedOptionId = (dropdownId) => {
  const focusedOption = document
    .getElementsByClassName(`dropdown_${dropdownId}`)[0]
    ?.getElementsByClassName("ant-select-item-option-active")[0];
  if (focusedOption) {
    return focusedOption.getAttribute("data-optionid");
  }
};

// Desktop select component emulates the behaviour of the
// traditional select box from desktop applications.
const CustomAntSelect = ({
  uppercase = true,
  mode,
  options,
  value,
  onChange,
  onKeyDown,
  onNext,
  readOnly,
  skipFocus,
  tabIndex,
  placeholder,
  disabled,
  openMenuOnFocus = true,
  required,
  forwardedRef,
  allowNewOption,
  allowEditOption,
  handleClickNewOption,
  handleClickEditOption,
  focusedOptionDetailsRender,
  defaultActiveFirstOption = true,
  footer,
  showSearch,
  ...restProps
}) => {
  // Override tabIndex if the field is read only
  if (readOnly || skipFocus) {
    tabIndex = -1;
  }

  // We have to use ref for this callback otherwise we are not able to use its value in shortcut handler
  const handleClickNewOptionRef = useRef(handleClickNewOption);
  const handleClickEditOptionRef = useRef(handleClickEditOption);
  const ref = forwardedRef || null;
  const dropdownIdRef = useRef(nanoid());
  const [focusedOptionId, setFocusedOptionId] = useState();
  const openMenuTimeoutRef = useRef();

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState();

  const handleKeyDown = (event) => {
    switch (event.key) {
      case "F3":
        if (allowNewOption) {
          event.stopPropagation();
          event.preventDefault();
          handleClickNewOptionRef.current?.();
        }
        break;
      case "F4":
        if (allowEditOption) {
          event.stopPropagation();
          event.preventDefault();
          handleClickEditOptionRef.current?.(focusedOptionId);
        }
        break;
      // Override the default web standards of opening the select dropdown on enter press.
      // Rather, the enter press should move the focus to next element
      // which is implemented on a global level in index.js.
      // So we just need to close the dropdown menu here
      case "Enter":
        if (mode === "multiple" && open) {
          event.stopPropagation();
        } else {
          setTimeout(() => setOpen(false), 10);
        }
        break;

      case "ArrowDown": {
        if (!open) {
          setOpen(true);
        }
        setTimeout(() => setFocusedOptionId(getFocusedOptionId(dropdownIdRef.current)), 10);
        break;
      }
      case "ArrowUp": {
        setTimeout(() => setFocusedOptionId(getFocusedOptionId(dropdownIdRef.current)), 10);
        break;
      }

      case "Escape":
        if (open) {
          event.preventDefault();
          event.stopPropagation();
          setOpen(false);
        }
        break;

      case "Delete":
        setSearchValue(null);
        break;

      // Tab and Shift+Tab should move the focus back and forth as usual
      case "Tab":
      case "Shift":
        break;

      // Any other key except 0 should open the dropdown menu since 0 is used to escape
      default:
        if (!open && event.key !== "0") setOpen(true);
        setTimeout(() => setFocusedOptionId(getFocusedOptionId(dropdownIdRef.current)), 50);
    }

    // Override the global behaviour of moving the focus to previous element in select box
    if (event.key === "ArrowUp") {
      event.preventDefault();
    }

    if (onKeyDown) {
      onKeyDown(event);
    }

    if (event.key === "Enter" || (event.key === "Tab" && !event.shiftKey)) {
      if (onNext) {
        onNext(event);
      }
    }
  };

  useEffect(() => {
    setFocusedOptionId(value);
  }, [value]);

  useEffect(() => {
    if (open) {
      setTimeout(() => setFocusedOptionId(getFocusedOptionId(dropdownIdRef.current)), 100);
    }
  }, [open]);

  const handleClick = (e) => {
    if (!open) {
      setOpen(true);
      return;
    }

    // If the menu is already open then it should only close on cliking one of the options
    if (e.target.localName === "div") {
      setOpen(false);
    }
  };

  const handleBlur = () => {
    clearTimeout(openMenuTimeoutRef.current);
    setOpen(false);
  };

  const upperCasedSearchValue = searchValue?.toUpperCase();
  const filteredOptions = searchValue
    ? options
      .filter((obj) => obj.label.toUpperCase().includes(upperCasedSearchValue))
      .sort((a, b) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();

        if (
          labelA.startsWith(upperCasedSearchValue) !== labelB.startsWith(upperCasedSearchValue)
        ) {
          if (
            labelA.startsWith(upperCasedSearchValue) &&
            !labelB.startsWith(upperCasedSearchValue)
          ) {
            return -1;
          }
          return 1;
        }
        return labelA < labelB ? -1 : 1;
      })
    : options;

  return (
    <FloatLabel label={placeholder} required={required}>
      <Select
        mode={mode}
        className={`custom-input ${readOnly ? "read-only" : ""}`}
        ref={ref}
        options={filteredOptions}
        value={value}
        onChange={readOnly ? null : onChange}
        searchValue={searchValue}
        onSearch={(value) => setSearchValue(uppercase ? value.toUpperCase() : value)}
        open={readOnly ? false : open}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        onBlurCapture={handleBlur}
        tabIndex={tabIndex}
        disabled={disabled}
        // Note we are calling `setOpen` in setTimeout to introduce a sligt delay
        // for fixing bug of wrong item focused when menu opens on focus
        onFocus={() => {
          if (openMenuOnFocus) {
            openMenuTimeoutRef.current = setTimeout(() => setOpen(true));
          }
        }}
        defaultActiveFirstOption={defaultActiveFirstOption}
        popupClassName={`dropdown_${dropdownIdRef.current}`}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {focusedOptionDetailsRender && (
              <>
                <Divider style={{ margin: 0 }} />
                {focusedOptionDetailsRender(focusedOptionId)}
              </>
            )}
            {!readOnly && (allowNewOption || allowEditOption) && (
              <>
                <Divider style={{ margin: 0 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "white",
                    paddingTop: 8,
                    paddingBottom: 4,
                  }}
                >
                  <Space>
                    {allowNewOption && (
                      <Button
                        type="primary"
                        size="small"
                        onMouseDown={() => {
                          setOpen(false);
                          setTimeout(() => handleClickNewOption?.(), 100);
                        }}
                      >
                        New (F3)
                      </Button>
                    )}
                    {allowEditOption && (
                      <Button
                        size="small"
                        onMouseDown={() => {
                          setOpen(false);
                          setTimeout(() => handleClickEditOption?.(focusedOptionId), 100);
                        }}
                      >
                        Edit (F4)
                      </Button>
                    )}
                  </Space>
                </div>
              </>
            )}
            {footer}
          </div>
        )}
        showSearch={showSearch}
        filterOption={() => true}
        {...restProps}
      />
    </FloatLabel>
  );
};

export default CustomAntSelect;
