class LocalEventsManager {
  constructor() {
    this.listeners = {};
  }

  notify(eventKey, data) {
    if (this.listeners[eventKey]) {
      this.listeners[eventKey].forEach((listener) => {
        listener.cb(data);
      });
      this.listeners[eventKey] = this.listeners[eventKey].filter(listener => listener.type !== "once");
    }
  }

  subsrcibeOnce(eventKey, cb) {
    if (this.listeners[eventKey]) {
      this.listeners[eventKey].push({ cb, type: "once" });
    } else {
      this.listeners[eventKey] = [{ cb, type: "once" }];
    }
  }

  subscribeAlways(eventKey, cb) {
    if (this.listeners[eventKey]) {
      this.listeners[eventKey].push({ cb, type: "always" });
    } else {
      this.listeners[eventKey] = [{ cb, type: "always" }];
    }
  }

  removeListeners(eventKey) {
    delete this.listeners[eventKey];
  }
}

const localEventsManager = new LocalEventsManager();
export default localEventsManager;
